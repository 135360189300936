<template>
<b-card no-body>
  <validation-observer
    v-slot="{ handleSubmit }" ref="VForm"
  >
    <b-form @submit.prevent="handleSubmit($parent.doSubmitCRUD('VForm', $refs))">
      <b-card-header>
        <b-row>
          <b-col lg="8">
            <h5 class="card-title">{{ $parent.isAdd?'Tambah':'Edit' }} {{ $parent.pageTitle }}</h5>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col lg="8">
            <b-row>
              <b-col lg="6">
                <b-form-group>
                  <label>Nama Template<span class="text-danger mr5">*</span></label>
                  <b-form-input :formatter="$parent.normalText" placeholder="Nama Template" type="text" class="form-control" v-model="row.mtr_name"/>
                  <VValidate 
                    name="Nama Template" 
                    v-model="row.mtr_name" 
                    :rules="toValidate(mrValidation.mtr_name)"
                  />
                </b-form-group>
              </b-col>
              
              <b-col lg="6">
                <b-form-group>
                  <label>Dokter<span class="text-danger mr5">*</span></label>
                  <v-select :disabled="$parent.isDokter" placeholder="Pilih Dokter" v-model="row.mtr_dokter"
                  :options="mDokter" label="text" :clearable="true"
                  :reduce="v=>v.value"></v-select>
                  <VValidate 
                    name="Dokter" 
                    v-model="row.mtr_dokter" 
                    :rules="toValidate(mrValidation.mtr_dokter)"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12">
            <b-form-group>
              <label>Resep<span class="text-danger mr5">*</span></label>
              <table class="table table-striped table-sm table-hover table-bordered">
                <thead>
                <tr>
                    <th width="48">Aksi</th>
                    <th width="160">Jenis</th>
                    <th width="320">Nama</th>
                    <th width="150">Jumlah</th>
                    <th width="210">Frekuensi</th>
                    <th>Keterangan</th>
                </tr>
                <tr v-for="(v,k) in (row.data||[])" :key="k">
                  <td>
                    <a href="javascript:;" @click="removeResep(k)"
                        class="btn btn-sm alpha-danger border-danger text-danger-800 btn-icon rounded-round"><i
                        class="icon-trash"></i></a>
                  </td>
                  <td>
                    <v-select placeholder="Pilih Jenis" v-model="row.data[k].mtri_jenis"
                      :options="configResep" label="text"
                      @input="changeJenis($event,k)" :reduce="v=>v.value">
                    </v-select>
                    <VValidate :name="'Jenis '+(k+1)" message="Jenis Harus Diisi"
                      v-model="row.data[k].mtri_jenis" :rules="{required : 1}" />
                  </td>
                  
                  <td>
                    <div class="d-flex">
                      <div style="width: 90%;">
                      <v-select placeholder="Pilih Nama Item" v-model="row.data[k].mtri_item_id"
                        :options="(paginatedItems(v.mtri_jenis)||[])" @input="selectedItems($event,row.data[k].mtri_jenis,k)" label="text"
                        @open="resetOpt" @search="filterOpt($event,k)"
                        class="med-selection select-paging select-custom-height" 
                        :reduce="v=>v.value" :clearable="true">
                          <template slot="selected-option">
                              <span>{{ getConfigDynamic(mSelect(v.mtri_jenis),row.data[k].mtri_item_id)||"-" }}</span>
                          </template>
                          
                          <template slot="option" slot-scope="option">
                              <span>{{option.text}}</span>
                          </template>

                          <template #list-footer>
                          <li class="list-paging">
                              <div class="d-flex align-items-center justify-content-between">
                              <b-button
                                  @click="prevPage(v.mtri_jenis)"
                                  size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                  <i class="icon-arrow-left22 text-white"></i></b-button>
                              <small>Halaman {{currentPage}} dari
                                  {{totalPages(v.mtri_jenis)}}</small>
                              <b-button
                                  @click="nextPage(v.mtri_jenis)"
                                  size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                  <i class="icon-arrow-right22 text-white"></i></b-button>
                              </div>
                          </li>
                          </template>

                      </v-select>
                      </div>
                      
                      <a href="javascript:;" @click="row.data[k]['mtri_item_id'] = 99999; row.data[k]['mtri_name'] = 'Lainnya'"
                      v-b-tooltip.hover="'Pilih Lainnya'"
                      class="btn btn-sm btn-icon alpha-info border-info btn-plus-other"><i
                      class="icon-plus-circle2"></i></a>
                    </div>
                    
                    <div v-if="row.data[k]['mtri_item_id'] == 99999">
                        <b-form-input type="text" v-model="row.data[k]['mtri_item_lainnya']"
                        class="form-control" id="itemCount" />

                        <VValidate :name="'Item Lainnya '+(k+1)"
                        message="Nama Item Lainnya Harus Diisi"
                        v-model="row.data[k]['mtri_item_lainnya']" :rules="{required : 1}" />
                    </div>

                    <VValidate :name="'Nama Item'+(k+1)" message="Nama Item Harus Diisi"
                      v-model="row.data[k].mtri_item_id" :rules="{required : 1}" />

                      
                  </td>

                  <td>
                    <div class="input-group">
                      <b-form-input :formatter="$parent.number" placeholder="Jumlah" type="text" class="form-control" v-model="row.data[k].mtri_jumlah"/>
                      <div class="input-group-append">
                        <span class="input-group-text">{{row.data[k]['mtri_satuan']}}</span>
                      </div>
                    </div>
                    
                    <VValidate :name="'Jumlah '+(k+1)" message="Jumlah Harus Diisi"
                      v-model="row.data[k].mtri_jumlah" :rules="{required : 1}" />
                      
                  </td>

                  <td>
                    <div class="d-flex">
                        <v-select style="flex: 1;" class="med-selection select-height"
                        placeholder="Pilih Frekuensi" v-model="row.data[k].mtri_frekuensi"
                        :options="mDosis" label="text" :clearable="true" :reduce="v=>v.value">
                        </v-select>
                        <a href="javascript:;" @click="row.data[k]['mtri_frekuensi'] = 99999"
                        v-b-tooltip.hover="'Pilih Lainnya'"
                        class="btn btn-sm btn-icon alpha-info border-info btn-plus-other"><i
                            class="icon-plus-circle2"></i></a>
                    </div>
                    <VValidate :name="'Frekuensi '+(k+1)" message="Frekuensi Harus Diisi"
                        v-model="row.data[k]['mtri_frekuensi']" 
                        v-if="row.data[k].mtri_jenis != 2"
                        :rules="{required : 1}" />

                    <div v-if="row.data[k]['mtri_frekuensi'] == 99999">
                        <b-form-input type="text"
                        v-model="row.data[k]['mtri_frekuensi_lainnya']" class="form-control"
                        id="itemCount" />
                        <VValidate :name="'Frekuensi Lainnya '+(k+1)"
                        message="Frekuensi Lainnya Harus Diisi"
                        v-if="row.data[k].mtri_jenis != 2"
                        v-model="row.data[k]['mtri_frekuensi_lainnya']"
                        :rules="{required : 1}" />
                    </div>
                    

                  </td>

                  <td>
                    <b-form-textarea type="text" class="form-control" v-model="row.data[k].mtri_keterangan"/>
                  </td>
                </tr>
                <tr>
                  <td colspan="99" class="text-center">
                    <a href="javascript:;" @click="addResep()" class="btn alpha-blue"
                        data-toggle="modal" data-target="#modalResep">
                        <i class="icon-plus2 mr-1 align-middle"></i>
                        <span class="align-middle">Tambah Resep</span>
                    </a>
                  </td>
                </tr>
                </thead>
                <tbody>
                </tbody>
              </table>
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <b-form-group class="mt-3">
              <label>Status <span class="text-danger mr5">*</span></label>
              <b-form-radio-group
                :options="Config.mr.StatusOptions"
                v-model="row['mtr_is_active']"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-footer>
        <b-row>
          <b-col lg="12" class="text-right">
            <button @click="$router.back()" type="button" class="btn  btn-rounded  btn-default mr-3">Batal</button>
            <b-button type="submit" variant="primary" class="btn-rounded">Simpan</b-button>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-form>
  </validation-observer>
</b-card>
</template>

<script>
import $ from 'jquery'
const _ = global._

export default{
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object,
  },
  computed :{
    mDokter(){
      return this.$parent.mDokter
    },
    mObat(){
      return this.$parent.mObat
    },
    mAlatKesehatan(){
      return this.$parent.mAlatKesehatan
    },
    mAlatInfus(){
      return this.$parent.mAlatInfus
    },
    mDosis(){
      return this.$parent.mDosis
    }
  },
  data(){
    return {
      configResep : [
        {
          text : "Obat",
          value : 1
        },
        {
          text : "Alat Kesehatan",
          value : 2
        },
        {
          text : "Infus",
          value : 5
        },
      ],
      
      currentPage: 1,
      pageSize: 10,
      searchQuery: '',
    }
  },
  methods: {
    filterOpt(e,k){
      this.searchQuery = e
      this.currentPage = 1
    },
    resetOpt(){
      this.currentPage = 1 
      this.searchQuery = ''
    },
    nextPage(j) {
      if (this.currentPage < this.totalPages(j)) {
        this.currentPage++
        // this.paginatedItems(j)
      }
    },
    prevPage(j) {
      if (this.currentPage > 1) {
        this.currentPage--
        // this.paginatedItems(j)
      }
    },
    filteredItems(j) {
      return (this.mSelect(j)||[]).filter(item =>
        item.text.toLowerCase().includes(this.searchQuery.toLowerCase())
      )
    },
    paginatedItems(j) {
      const startIndex = (this.currentPage - 1) * this.pageSize
      const endIndex = startIndex + this.pageSize
      return this.filteredItems(j).slice(startIndex, endIndex)
    },
    totalPages(j) {
      return Math.ceil(this.filteredItems(j).length / this.pageSize) || 1
    },

    toValidate(val){
      return {...val}
    },
    addResep(){
      this.row.data.push({
        mtri_mtr_id : this.$route.params.pageSlug,
        mtri_name : null,
        mtri_jenis : 1, // dflt obat
        mtri_item_id : null,
        mtri_item_lainnya : null,
        mtri_jumlah : null,
        mtri_dosis : null,
        mtri_satuan_dosis : null,
        mtri_frekuensi : null,
        mtri_frekuensi_lainnya : null,
        mtri_satuan : null,
        mtri_keterangan : null,
      })
    },
    removeResep(k){
      this.row.data.splice(k, 1)
    },
    changeJenis(e,k){
      this.row.data[k].mtri_item_id = null
      this.row.data[k].mtri_frekuensi = null
      this.row.data[k].mtri_frekuensi_lainnya = null
      this.row.data[k].mtri_satuan = null
    },
    mSelect(e){
      if(e == 1){
        return this.mObat
      }else if(e == 2){
        return this.mAlatKesehatan
      }else{
        return this.mAlatInfus
      }
    },
    selectedItems(e,j,k){
      let index = (this.mSelect(j)||[]).findIndex(x => x.value == e)
      this.row.data[k]['mtri_name'] = this.mSelect(j)[index]['text']
      this.row.data[k]['mtri_satuan'] = this.mSelect(j)[index]['satuan']

      console.log(this.row.data[k]['mtri_name'])
    },
    
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
          let index = (master||[]).findIndex(x => x.value == value)
          if (index !== -1) {
              text = master[index]['text']
          }
      }
      return text
    },
  },
}
</script>